import React, { useEffect } from "react";
import Page from "../components/cookies-policy";

export default function CookiesPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Page />
    </div>
  );
}
